import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./styles/app.scss";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faMagnifyingGlass,
  faEnvelopeCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(
  faMagnifyingGlass,
  faEnvelopeCircleCheck,
  faXmark,
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter
);
const app = createApp(App);

app.config.globalProperties.$filters = {
  nl2br(str: string | null | undefined): string {
    if (typeof str === "undefined" || str === undefined || str === null)
      return "";
    const breakTag = "<br />";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  },
};

import VueFinalModal from "vue-final-modal";
app.use(VueFinalModal);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vueVimeoPlayer from "vue-vimeo-player";
app.use(vueVimeoPlayer);

import ToastPlugin from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
app.use(ToastPlugin);

app
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
