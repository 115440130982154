export default `...on featureSets_openTextGallery_BlockType {
    id
    typeHandle
    galleryImages {
        id
        url
        title
    }
    openText
    buttonLink {
        ...on buttonLink_BlockType {
            id
            linkTitle
            hyperlink
            newTab
        }
    }
}`;
