
import { defineComponent } from "vue";
import api from "@/api";
import apiHelper from "@/api/helpers/helpers";
import { mapActions } from "vuex";
import HeroBlock from "@/components/Hero/HeroBlock.vue";
import FeatureSetsBlock from "@/components/FeatureSets/FeatureSetsBlock.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    FeatureSetsBlock,
    HeroBlock,
  },
  data() {
    return {
      heroData: null,
      featureSetsData: null,
    };
  },
  mounted() {
    this.loadHomepage();

    setTimeout(() => {
      this.toggleShowPageTrue();
    }, 1000);
  },
  methods: {
    ...mapActions("page", ["toggleShowPageTrue"]),
    loadHomepage() {
      this.setHomepageData(apiHelper.readStoredResponse("homepage", null));

      api.home.loadHomePage().then((response: any) => {
        this.setHomepageData(response);
      });
    },
    setHomepageData(response: any) {
      if (!response || !response.entry) return;

      this.heroData = response.entry.hero[0] || null;
      this.featureSetsData = response.entry.featureSets || null;
    },
  },
});
