export default `basicContactDetails {
    ...on basicContactDetails_default_BlockType {
        id,
        titleText,
        contactInformation {
            ...on contactInformation_BlockType {
                id,
                contactType,
                contactDetails
            }
        }
    }
}`;
