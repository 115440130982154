
import { defineComponent } from "vue";
import api from "@/api";
import apiHelper from "@/api/helpers/helpers";
import AnchorLink from "@/components/UI/AnchorLink.vue";
import { AnchorLink as AnchorLinkInterface } from "@/interfaces/AnchorLink";
import SocialMediaLinks from "@/components/SocialMedia/SocialMediaLinks.vue";

export default defineComponent({
  name: "FooterNavigation",
  components: { SocialMediaLinks, AnchorLink },
  data() {
    return {
      footerLinksList: [] as Array<AnchorLinkInterface>,
      socialMediaLinks: [] as Array<any>,
    };
  },
  computed: {
    leftFooterLinks(): Array<AnchorLinkInterface> {
      return this.footerLinksList.slice(
        0,
        Math.floor(this.footerLinksList.length / 2)
      );
    },
    rightFooterLinks(): Array<AnchorLinkInterface> {
      return this.footerLinksList.slice(
        Math.floor(this.footerLinksList.length / 2),
        this.footerLinksList.length
      );
    },
  },
  mounted() {
    this.loadFooterContent();
  },
  methods: {
    loadFooterContent() {
      this.setFooterContent(
        apiHelper.readStoredResponse("globals.footer", null)
      );
      api.globals.loadFooter().then((response: any) => {
        this.setFooterContent(response);
      });
    },
    setFooterContent(response: any) {
      if (!response || !response.globalSet) return;

      this.footerLinksList = response.globalSet.linkList;
      this.socialMediaLinks = response.globalSet.socialMediaLinks
        ? response.globalSet.socialMediaLinks[0]
        : [];
    },
  },
});
