
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeadingScrollingBackground",
  props: {
    featureSetData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return this.featureSetData.backgroundImage &&
        this.featureSetData.backgroundImage.length
        ? this.featureSetData.backgroundImage[0]
        : null;
    },
  },
});
