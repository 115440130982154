export default `...on hero_secondaryHero_BlockType {
    id,
    typeHandle,
    titleText,
    descriptionText,
    backgroundImage {
        id,
        url,
        title,
    }
}`;
