import seo from "@/api/components/common/seo";
import helpers from "@/api/helpers/helpers";
import documentsWithImagesList from "@/api/components/documents/documentsWithImagesList";
import unorderedDocumentsList from "@/api/components/documents/unorderedDocumentsList";
import image from "@/api/components/attachment/image";

export default {
  loadDocumentsAndFormsPage() {
    const requestData = {
      query: `{
        entry(slug: "documents-and-forms") {
          ... on documentsAndForms_documentsAndForms_Entry {
            ${seo}
            ${image}
            ${documentsWithImagesList}
            ${unorderedDocumentsList}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "documentsAndFormsPage");
  },
};
