export default `employmentContactForm {
    ... on employmentContactForm_default_BlockType {
        id
        titleText
        emailSubject
        emailRecipients {
            ... on emailRecipients_BlockType {
                id
                emailAddress
            }
        }
        successMessage
    }
}`;
