
import { defineComponent, PropType } from "vue";
import AnchorLink from "@/components/UI/AnchorLink.vue";
import { AnchorLink as AnchorLinkInterface } from "@/interfaces/AnchorLink";

export default defineComponent({
  name: "RedButtonAnchorLink",
  components: { AnchorLink },
  props: {
    link: {
      type: Object as PropType<AnchorLinkInterface>,
      required: true,
    },
  },
});
