import { CommitFunction, CommitStateFunction } from "@/store/interfaces";

const state = {
  showPage: false as boolean,
};
type State = typeof state;

const getters = {
  //
};

const mutations = {
  toggleShowPage(state: State) {
    state.showPage = !state.showPage;
  },
};

const actions = {
  toggleShowPage({ commit }: CommitFunction) {
    commit("toggleShowPage");
  },
  toggleShowPageTrue({ commit, state }: CommitStateFunction<State>) {
    if (!state.showPage) {
      commit("toggleShowPage");
    }
  },
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
