import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isRouterLink)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: { path: _ctx.link.hyperlink, hash: _ctx.hash }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.link.linkTitle), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (!_ctx.isReferenceLink)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.link.hyperlink,
          target: "_blank"
        }, _toDisplayString(_ctx.link.linkTitle), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("a", {
          key: 2,
          href: _ctx.link.hyperlink,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.scrollToReference && _ctx.scrollToReference(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.link.linkTitle), 9, _hoisted_2))
}