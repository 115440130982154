import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/our-products",
    redirect: { name: "Products" },
  },
  {
    path: "/products",
    name: "Products",
    // route level code-splitting
    // this generates a separate chunk (products.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: Products
    component: () =>
      import(
        /* webpackChunkName: "products" */
        "../views/ProductsListView.vue"
      ),
  },
  {
    path: "/products/:productSlug",
    name: "ProductDetails",
    // component: ProductDetails
    component: () =>
      import(
        /* webpackChunkName: "productDetails" */
        "../views/ProductDetailsView.vue"
      ),
  },
  {
    path: "/our-story",
    name: "OurStory",
    // component: OurStory
    component: () =>
      import(
        /* webpackChunkName: "ourStory" */
        "../views/OurStory.vue"
      ),
  },
  {
    path: "/careers/:career",
    name: "CareerOpportunity",
    // component: CareerOpportunity
    component: () =>
      import(
        /* webpackChunkName: "careerOpportunity" */
        "../views/CareerOpportunityView.vue"
      ),
  },
  {
    path: "/documents-and-forms",
    name: "DocumentsAndForms",
    // component: DocumentsAndFormsView
    component: () =>
      import(
        /* webpackChunkName: "documentsAndForms" */
        "../views/DocumentsAndFormsView.vue"
      ),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    // component: ContactUsView
    component: () =>
      import(
        /* webpackChunkName: "contactUs" */
        "../views/ContactUsView.vue"
      ),
  },
  {
    path: "/search",
    name: "Search",
    // component: Search
    component: () =>
      import(
        /* webpackChunkName: "search" */
        "../views/SearchView.vue"
      ),
  },
  {
    path: "/page-not-found",
    name: "PageNotFound",
    // component: PageNotFound
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */
        "../views/PageNotFoundView.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { name: "PageNotFound" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to /*, from, savedPosition*/) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    // always scroll to top
    return { top: 0 };
  },
});

export default router;
