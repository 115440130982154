import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroBlock = _resolveComponent("HeroBlock")!
  const _component_FeatureSetsBlock = _resolveComponent("FeatureSetsBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.heroData)
      ? (_openBlock(), _createBlock(_component_HeroBlock, {
          key: 0,
          "hero-data": _ctx.heroData,
          onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleShowPageTrue()))
        }, null, 8, ["hero-data"]))
      : _createCommentVNode("", true),
    (_ctx.featureSetsData)
      ? (_openBlock(), _createBlock(_component_FeatureSetsBlock, {
          key: 1,
          "feature-sets-data": _ctx.featureSetsData
        }, null, 8, ["feature-sets-data"]))
      : _createCommentVNode("", true)
  ]))
}