export default `...on hero_primaryHero_BlockType {
    id,
    typeHandle,
    titleText,
    backgroundImage {
        id,
        url,
        title,
    },
}`;
