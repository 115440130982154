export default `productHeader {
    ...on productHeader_defaultProductHeader_BlockType {
        headerImage {
            url,
            title,
        }
        productImage {
            url
            title
        }
        titleText,
        productDescription
    }
}`;
