import globals from "@/api/globals";
import home from "@/api/home";
import ourStory from "@/api/ourStory";
import contactUs from "@/api/contactUs";
import careerDetails from "@/api/careerDetails";
import products from "@/api/products";
import documentsAndForms from "@/api/documentsAndForms";
import search from "@/api/search";

export default {
  careerDetails,
  contactUs,
  documentsAndForms,
  globals,
  home,
  ourStory,
  products,
  search,
};
