export default `...on featureSets_tallBackgroundImageWhiteContent_BlockType {
    id,
    typeHandle,
    titleText,
    backgroundImage {
        id,
        url,
        title,
    }
    button {
        ...on button_BlockType {
            id,
            linkTitle,
            hyperlink,
            newTab,
        }
    }
}`;
