import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af08de2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "open-text-background-bottom-focused-container",
    style: _normalizeStyle(`background-image: url('${_ctx.backgroundImage.url}');`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.featureSetData.titleText), 1),
        _createElementVNode("div", {
          innerHTML: _ctx.$filters.nl2br(_ctx.featureSetData.description)
        }, null, 8, _hoisted_3)
      ])
    ])
  ], 4))
}