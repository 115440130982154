
import { defineComponent } from "vue";
import MoveUpOnScrollDown from "@/components/UI/Animations/MoveUpOnScrollDown.vue";
export default defineComponent({
  name: "SecondaryHero",
  components: { MoveUpOnScrollDown },
  props: {
    heroData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      containerHeight: 0,
      containerOffsetTop: 0,
    };
  },
  computed: {
    backgroundImage() {
      return this.heroData.backgroundImage[0] || null;
    },
  },
  mounted() {
    this.containerHeight = this.$el.clientHeight;
    this.containerOffsetTop = this.$el.offsetTop;
  },
});
