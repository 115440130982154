import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";
import basicContactDetails from "@/api/components/contact/basicContactDetails";
import image from "@/api/components/attachment/image";
import contactForm from "@/api/components/contact/contactForm";

export default {
  loadContactUsPage() {
    const requestData = {
      query: `{
        entry(slug: "contact-us") {
          ...on contactUs_contactUs_Entry {
            ${seo}
            ${image}
            ${basicContactDetails}
            ${contactForm}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "contactUsPage");
  },
};
