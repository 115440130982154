
import { capitalize, defineComponent } from "vue";
import PrimaryHero from "@/components/Hero/PrimaryHero.vue";
import SecondaryHero from "@/components/Hero/SecondaryHero.vue";

export default defineComponent({
  name: "HeroBlock",
  components: { PrimaryHero, SecondaryHero },
  props: {
    heroData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    heroComponent() {
      return capitalize(this.heroData.typeHandle);
    },
    backgroundImage() {
      return this.heroData.backgroundImage[0] || null;
    },
  },
  created() {
    this.preloadBackgroundImage();
  },
  methods: {
    preloadBackgroundImage() {
      const preloader = document.createElement("img");

      preloader.addEventListener("load", () => {
        setTimeout(() => {
          this.$emit("loaded");
        }, 300);
      });

      preloader.src = this.backgroundImage.url;
    },
  },
});
