
import { defineComponent } from "vue";
export default defineComponent({
  name: "BasicHeadingDescription",
  props: {
    featureSetData: {
      type: Object,
      required: true,
    },
  },
});
