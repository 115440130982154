import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";
import hero from "@/api/components/common/hero";
import featureSets from "@/api/components/common/featureSets";

export default {
  loadHomePage() {
    const requestData = {
      query: `{
        entry(slug: "home") {
          ... on home_home_Entry {
            ${seo}
            ${hero}
            ${featureSets}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "homepage");
  },
};
