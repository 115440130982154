import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";
import productList from "@/api/components/products/productList";
import productHeader from "@/api/components/products/productHeader";
import productSpecifications from "@/api/components/products/productSpecifications";
import productVideoUrl from "@/api/components/products/productVideoUrl";

export default {
  loadProductsPage() {
    const requestData = {
      query: `{
        entry(slug: "products") {
          ... on products_products_Entry {
            ${seo}
            ${productList}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "products");
  },

  loadProductDetailsPage(slug: string) {
    const requestData = {
      query: `{
        entry(uri: "products/${slug}") {
          ... on productDetails_default_Entry {
            ${seo}
            ${productHeader}
            ${productSpecifications}
            ${productVideoUrl}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "products." + slug);
  },
};
