
import { defineComponent } from "vue";
import RedButtonAnchorLink from "@/components/UI/RedButtonAnchorLink.vue";
import RightToLeftFade from "@/components/UI/Animations/RightToLeftFade.vue";

export default defineComponent({
  name: "OpenTextOrangeBackground",
  components: { RightToLeftFade, RedButtonAnchorLink },
  props: {
    featureSetData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    buttonLink() {
      return this.featureSetData.button && this.featureSetData.button.length
        ? this.featureSetData.button[0]
        : null;
    },
  },
});
