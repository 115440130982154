export default `contactForm {
    ...on contactForm_default_BlockType {
        emailSubject,
        emailRecipients {
            ...on emailRecipients_BlockType {
                id,
                emailAddress
            }
        }
        successMessage
    }
}`;
