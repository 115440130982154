export default `productList {
    ...on productList_defaultProductList_BlockType {
        id,
        typeHandle,
        titleText,
        products {
            ...on productDetails_default_Entry {
                id,
                typeHandle,
                uri,
                productTile {
                    ...on productTile_defaultProductTile_BlockType {
                        id,
                        titleText,
                        tileImage {
                            id,
                            url,
                            title
                        },
                    }
                }
            }
        }
        productListDetails,
    }
}`;
