
import { defineComponent } from "vue";
import AnchorLink from "@/components/UI/AnchorLink.vue";
import api from "@/api";
import apiHelper from "@/api/helpers/helpers";
import MobileNavigation from "@/components/Template/MobileNavigation.vue";

const NAV_MAX_WIDTH = 992;

export default defineComponent({
  name: "PrimaryNavigation",
  components: { MobileNavigation, AnchorLink },
  data() {
    return {
      logoImage: null as null | { url: string | null },
      navItems: [],
      renderMobileNav: false,
    };
  },
  computed: {
    logoImageUrl(): string | null {
      if (!this.logoImage || !this.logoImage.url) return null;
      return this.logoImage.url;
    },
  },
  watch: {
    logoImageUrl(newVal) {
      if (newVal.length) {
        this.swapLogoImage(newVal);
      }
    },
  },
  mounted() {
    this.loadHeaderContent();
    window.addEventListener("resize", this.listenForResize);
    this.listenForResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.listenForResize);
  },
  methods: {
    loadHeaderContent() {
      this.setHeaderContent(
        apiHelper.readStoredResponse("globals.header", null)
      );
      api.globals.loadHeader().then((response: any) => {
        this.setHeaderContent(response);
      });
    },
    setHeaderContent(response: any) {
      if (!response || !response.globalSets) return;

      const headerLogo =
        response.globalSets.find(
          (globalSet: { handle: string }) => globalSet.handle === "headerLogo"
        ) || null;
      this.logoImage = headerLogo ? headerLogo.image[0] : headerLogo;

      const mainMenu =
        response.globalSets.find(
          (globalSet: { handle: string }) => globalSet.handle === "mainMenu"
        ) || null;
      this.navItems = mainMenu ? mainMenu.linkList : [];
    },
    swapLogoImage(newVal: string) {
      const imageLoader = document.createElement("img");
      imageLoader.onload = () => {
        const logoImageRef = this.$refs.logoImage as HTMLImageElement;
        logoImageRef.src = newVal;
      };
      imageLoader.src = newVal;
    },
    listenForResize() {
      this.renderMobileNav = window.innerWidth < NAV_MAX_WIDTH;
    },
  },
});
