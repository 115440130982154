
import { defineComponent } from "vue";
import TransparentButtonAnchorLink from "@/components/UI/TransparentButtonAnchorLink.vue";
import FadeIn from "@/components/UI/Animations/FadeIn.vue";
export default defineComponent({
  name: "TallBackgroundImageWhiteContent",
  components: { FadeIn, TransparentButtonAnchorLink },
  props: {
    featureSetData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    buttonLink() {
      return this.featureSetData.button && this.featureSetData.button.length
        ? this.featureSetData.button[0]
        : null;
    },
    backgroundImage() {
      return this.featureSetData.backgroundImage &&
        this.featureSetData.backgroundImage.length
        ? this.featureSetData.backgroundImage[0]
        : null;
    },
  },
});
