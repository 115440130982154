import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";

export default {
  loadSearchPage() {
    const requestData = {
      query: `{
        entry(slug: "search") {
          title
          ...on search_search_Entry {
            ${seo}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "search");
  },
  searchContent(keywords: string) {
    const requestData = {
      query: `{
        entries(search: "${keywords}", orderBy: "score") {
          id
          searchScore
          title
          url
          uri
          ... on home_home_Entry {
            seo {
              title
              description
            }
          }
          ...on products_products_Entry {
            seo {
              title
              description
            }
          }
          ...on productDetails_default_Entry {
            seo {
              title
              description
            }
          }
          ...on ourStory_ourStory_Entry {
            seo {
              title
              description
            }
          }
          ...on documentsAndForms_documentsAndForms_Entry {
            seo {
              title
              description
            }
          }
          ...on careers_default_Entry{
            seo {
              title
              description
            }
          }
        }
      }`,
    };

    return helpers.makeContentRequest(
      requestData,
      "search." + helpers.slugify(keywords)
    );
  },
};
