import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "feature-sets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.featureSetsData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featureSetsData, (featureSet) => {
          return (_openBlock(), _createElementBlock("div", {
            key: featureSet.id,
            class: "feature-set"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.capitalize(featureSet.typeHandle)), { "feature-set-data": featureSet }, null, 8, ["feature-set-data"]))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}