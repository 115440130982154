<template>
  <div class="open-text-gallery">
    <div class="top-gallery-container">
      <BottomToTopFade :threshold="1">
        <img :src="galleryImages[0].url" :alt="galleryImages[0].title" />
      </BottomToTopFade>
    </div>
    <div class="center-container">
      <div class="left-gallery-container">
        <LeftToRightFade class="animator" :threshold="1" animation-delay="0.3s">
          <img :src="galleryImages[1].url" :alt="galleryImages[1].title" />
        </LeftToRightFade>

        <LeftToRightFade class="animator" :threshold="1" animation-delay="0.3s">
          <img :src="galleryImages[3].url" :alt="galleryImages[3].title" />
        </LeftToRightFade>
      </div>

      <div class="content-container">
        <div class="content">
          <div v-html="$filters.nl2br(featureSetData.openText)"></div>
          <div v-if="buttonLink" class="button-container">
            <RedButtonAnchorLink class="button" :link="buttonLink" />
          </div>
        </div>
      </div>

      <div class="right-gallery-container">
        <RightToLeftFade class="animator" :threshold="1">
          <img :src="galleryImages[2].url" :alt="galleryImages[2].title" />
        </RightToLeftFade>

        <RightToLeftFade class="animator" :threshold="1">
          <img :src="galleryImages[4].url" :alt="galleryImages[4].title" />
        </RightToLeftFade>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import RedButtonAnchorLink from "@/components/UI/RedButtonAnchorLink";
import BottomToTopFade from "@/components/UI/Animations/BottomToTopFade";
import LeftToRightFade from "@/components/UI/Animations/LeftToRightFade";
import RightToLeftFade from "@/components/UI/Animations/RightToLeftFade";
export default defineComponent({
  name: "OpenTextGallery",
  components: {
    RightToLeftFade,
    LeftToRightFade,
    BottomToTopFade,
    RedButtonAnchorLink,
  },
  props: {
    featureSetData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    galleryImages() {
      return this.featureSetData.galleryImages;
    },
    buttonLink() {
      return this.featureSetData.buttonLink.length
        ? this.featureSetData.buttonLink[0]
        : null;
    },
  },
});
</script>

<style lang="scss" scoped>
.open-text-gallery {
  @apply relative overflow-x-hidden;
  background-image: linear-gradient(
      rgba(193, 133, 21, 0) 99%,
      rgb(171, 115, 12) 100%
    ),
    linear-gradient(#f5c352, #d59d23);

  .top-gallery-container {
    @apply flex justify-center pt-16;
  }

  .top-gallery-container img,
  .center-container .left-gallery-container img,
  .center-container .right-gallery-container img {
    border: 10px solid #ffffff;
    max-width: 200%;
  }

  .top-gallery-container img {
    max-width: 500px;
  }

  .center-container .left-gallery-container img,
  .center-container .right-gallery-container img {
    height: 300px;
  }

  .center-container {
    @apply flex flex-wrap;

    .left-gallery-container,
    .right-gallery-container {
      @apply relative w-full flex flex-col justify-between;
    }

    .left-gallery-container {
      @apply items-center;

      .animator:first-of-type img {
        @apply relative mt-24 right-0;
      }

      .animator:nth-of-type(2) img {
        @apply relative mt-24 mb-0 right-0;
      }
    }

    .content-container {
      @apply w-full flex justify-center pt-24 mx-2;

      .content {
        @apply text-white text-center;
        max-width: 520px;
        font-size: 1.22em;

        .button-container {
          @apply mt-20;
        }
      }
    }

    .right-gallery-container {
      @apply items-center;

      .animator:first-of-type img {
        @apply relative left-0 mt-24;
      }

      .animator:nth-of-type(2) img {
        @apply relative top-0 left-0 mt-24 mb-24;
      }
    }
  }

  /**
  */
  @media only screen and (min-width: 480px) {
  }

  /**
  */
  @media only screen and (min-width: 768px) {
  }

  /**
  */
  @media only screen and (min-width: 992px) {
    .center-container {
      @apply flex-nowrap;

      .left-gallery-container,
      .right-gallery-container {
        @apply w-1/5;
      }

      .left-gallery-container {
        @apply items-end;

        .animator:first-of-type img {
          @apply mt-48 right-44;
        }

        .animator:nth-of-type(2) img {
          @apply right-12 mb-24;
          margin-top: 28rem;
        }
      }

      .content-container {
        @apply w-3/5 pt-24;
      }

      .right-gallery-container {
        @apply items-start;

        .animator:first-of-type img {
          @apply -left-4 mt-0;
        }

        .animator:nth-of-type(2) img {
          @apply -top-24 -left-36 mb-24;
        }
      }
    }
  }

  /**
  */
  @media only screen and (min-width: 1200px) {
    .center-container {
      .left-gallery-container {
        .animator:first-of-type img {
          @apply right-28;
        }
      }
    }
  }

  /**
  */
  @media only screen and (min-width: 1366px) {
  }
}
</style>
