export default `productSpecifications {
    ...on productSpecifications_defaultProductSpecification_BlockType {
        id,
        titleText,
        conventionalOrganic,
        productForm,
        documents {
            ...on documents_BlockType {
                id,
                documentTitle,
                document {
                    id,
                    title,
                    url,
                }
            }
        }
        packagingOptions {
            ...on packagingOptions_BlockType {
                id,
                packagingType,
                quantityDescription,
            }
        }
    }
}`;
