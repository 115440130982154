export default `highlightedDocuments {
    ...on highlightedDocuments_documentsWithImages_BlockType {
        id,
        titleText,
        description,
        documentsImageList {
            ...on documentsImageList_BlockType {
                id,
                documentTitle,
                image {
                    id,
                    title,
                    url
                }
                document {
                    id,
                    title,
                    url
                }
            }
        }
    }
}`;
