import openTextOrangeBackground from "@/api/components/common/featureSets/openTextOrangeBackground";
import headingScrollingBackground from "@/api/components/common/featureSets/headingScrollingBackground";
import basicHeadingDescription from "@/api/components/common/featureSets/basicHeadingDescription";
import tallBackgroundImageWhiteContent from "@/api/components/common/featureSets/tallBackgroundImageWhiteContent";
import openTextGallery from "@/api/components/common/featureSets/openTextGallery";
import tallBackgroundImageOrangeContent from "@/api/components/common/featureSets/tallBackgroundImageOrangeContent";

export default `featureSets {
    ${openTextOrangeBackground}
    ${headingScrollingBackground}
    ${basicHeadingDescription}
    ${tallBackgroundImageWhiteContent}
    ${openTextGallery}
    ${tallBackgroundImageOrangeContent}
}`;
