import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";
import careerDetails from "@/api/components/career/careerDetails";
import employmentContactForm from "@/api/components/contact/employmentContactForm";

export default {
  loadCareerDetailsPage(slug: string) {
    const requestData = {
      query: `{
        entry(uri: "careers/${slug}") {
          ... on careers_default_Entry {
            ${seo}
            ${careerDetails}
            ${employmentContactForm}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "careerDetails." + slug);
  },
};
