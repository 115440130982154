<template>
  <div class="mobile-navigation-container">
    <button
      :aria-label="mobileMenuActive ? 'Close mobile menu' : 'Open mobile menu'"
      id="mobileMenuButton"
      :class="{ 'is-fixed': mobileMenuActive, active: mobileMenuActive }"
      @click="mobileMenuActive = !mobileMenuActive"
    >
      <span class="bar" />
      <span class="bar" />
      <span class="bar" />
    </button>

    <nav
      :class="[
        {
          active: mobileMenuActive,
        },
        'mobile-navigation',
      ]"
    >
      <div class="logo-container">
        <router-link to="/">
          <img
            ref="logoImage"
            src="@/assets/Sunmetlogos_White-03.png"
            alt="Sunmet Fruit Logo"
          />
        </router-link>
      </div>

      <div v-for="navItem in navItems" :key="navItem.id" class="nav-link">
        <AnchorLink class="link" :link="navItem" />
      </div>
      <div class="nav-link">
        <router-link class="link" to="/search">
          <font-awesome-icon
            class="search-icon"
            icon="fa-solid fa-magnifying-glass"
          />
          Search
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import AnchorLink from "@/components/UI/AnchorLink";
export default {
  name: "MobileNavigation",
  components: { AnchorLink },
  props: {
    navItems: {
      type: Object,
      required: true,
    },
  },
  watch: {
    $route() {
      this.mobileMenuActive = false;
    },
  },
  data() {
    return {
      mobileMenuActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.mobile-navigation-container {
  #mobileMenuButton {
    @apply flex justify-between flex-col outline-none;
    width: 36px;
    height: 28px;
    z-index: 10002;

    &.is-fixed {
      @apply fixed top-12 right-4;
    }

    &.active {
      .bar:first-of-type {
        @apply rotate-45;
        top: 13px;
        left: -4px;
        width: 44px;
      }

      .bar:nth-of-type(2) {
        @apply opacity-0;
      }

      .bar:last-of-type {
        @apply -rotate-45;
        top: -13px;
        left: -4px;
        width: 44px;
      }
    }

    .bar {
      @apply relative;
      @apply bg-white;
      top: 0;
      left: 0;
      opacity: 1;
      width: 36px;
      height: 2px;
      transition: all 0.5s linear;
    }
  }

  .mobile-navigation {
    @apply fixed top-0 right-0 bottom-0 left-0 z-0 h-0 overflow-hidden flex flex-col justify-start items-center;
    left: -100%;
    background-color: #d0162e;
    transition: all 0.5s ease-in;

    &.active {
      @apply py-24 left-0;
      height: 100vh;
      z-index: 10001;
    }

    .logo-container {
      @apply mb-16;
    }

    .nav-link {
      @apply text-white my-2;
      .link {
        font-size: 1.5em;
        transition: all 0.3s ease-in;

        &:hover {
          font-size: 1.6em;
        }
      }
    }
  }

  @media only screen and (min-width: 480px) {
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 992px) {
    #mobileMenuButton {
      @apply hidden;
    }
  }

  @media only screen and (min-width: 1200px) {
  }

  @media only screen and (min-width: 1366px) {
  }
}
</style>
