import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6106bda1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "open-text-orange-background-container" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RedButtonAnchorLink = _resolveComponent("RedButtonAnchorLink")!
  const _component_RightToLeftFade = _resolveComponent("RightToLeftFade")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.featureSetData)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.featureSetData.openText
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createVNode(_component_RightToLeftFade, {
          threshold: 1,
          "animation-delay": "0.5s"
        }, {
          default: _withCtx(() => [
            (_ctx.buttonLink)
              ? (_openBlock(), _createBlock(_component_RedButtonAnchorLink, {
                  key: 0,
                  link: _ctx.buttonLink
                }, null, 8, ["link"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}