
import { defineComponent, PropType } from "vue";
import { AnchorLink as AnchorLinkInterface } from "@/interfaces/AnchorLink";

export default defineComponent({
  name: "AnchorLink",
  props: {
    link: {
      type: Object as PropType<AnchorLinkInterface>,
      required: true,
    },
  },
  computed: {
    isRouterLink() {
      return !(
        /^(http(s?)):\/\//i.test(this.link.hyperlink) ||
        /^(#)/i.test(this.link.hyperlink) ||
        /^(tel):/i.test(this.link.hyperlink) ||
        /^(fax):/i.test(this.link.hyperlink) ||
        /^(mailto):/i.test(this.link.hyperlink)
      );
    },
    isReferenceLink() {
      return /^(#)/i.test(this.link.hyperlink);
    },
    hash() {
      return this.link &&
        this.isRouterLink &&
        this.link.hyperlink &&
        this.link.hyperlink.indexOf("#") >= 0
        ? "#" + this.link.hyperlink.split("#")[1]
        : "";
    },
  },
  methods: {
    scrollToReference() {
      let targetReference = this.link.hyperlink.replace("#", "");
      const element = document.getElementById(targetReference);

      if (!element) return;

      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    },
  },
});
