export default `careerOpenings {
    ...on careerOpenings_defaultCareerOpenings_BlockType {
        id,
        typeHandle,
        titleText,
        careers {
            ...on careers_default_Entry {
                uri,
                careerdetails{
                    ...on careerdetails_defaultCareerDetailsItem_BlockType {
                        id,
                        titleText,
                        location,
                        postedDate,
                        shortDescription,
                    }
                }
            }
        },
        disclaimer
    }
}`;
