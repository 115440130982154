import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3290a484"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "secondary-hero-container" }
const _hoisted_2 = { class: "background-image-container" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "content-container" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoveUpOnScrollDown = _resolveComponent("MoveUpOnScrollDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MoveUpOnScrollDown, {
        "travel-percentage": 10,
        "parent-height": _ctx.containerHeight,
        "parent-offset-top": _ctx.containerOffsetTop
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.backgroundImage.url,
            alt: _ctx.backgroundImage.title
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["parent-height", "parent-offset-top"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.heroData.titleText), 1),
        _createElementVNode("div", {
          innerHTML: _ctx.heroData.descriptionText
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}