import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38c81db6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FadeIn = _resolveComponent("FadeIn")!
  const _component_TransparentButtonAnchorLink = _resolveComponent("TransparentButtonAnchorLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tall-hero-container",
    style: _normalizeStyle(`background-image: url('${_ctx.backgroundImage.url}')`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FadeIn, {
          threshold: 1,
          "animation-delay": "0.7s",
          rewinds: true
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.featureSetData.titleText), 1)
          ]),
          _: 1
        }),
        (_ctx.buttonLink)
          ? (_openBlock(), _createBlock(_component_FadeIn, {
              key: 0,
              threshold: 1,
              "animation-delay": "1.3s",
              rewinds: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TransparentButtonAnchorLink, { link: _ctx.buttonLink }, null, 8, ["link"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 4))
}