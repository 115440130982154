import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryNavigation = _resolveComponent("PrimaryNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!
  const _component_FooterNavigation = _resolveComponent("FooterNavigation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PrimaryNavigation),
    _createVNode(_component_PageWrapper, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_FooterNavigation)
  ], 64))
}