
import { defineComponent, capitalize } from "vue";
import OpenTextOrangeBackground from "@/components/Highlights/OpenTextOrangeBackground.vue";
import HeadingScrollingBackground from "@/components/Highlights/HeadingScrollingBackground.vue";
import BasicHeadingDescription from "@/components/Highlights/BasicHeadingDescription.vue";
import TallBackgroundImageWhiteContent from "@/components/Highlights/TallBackgroundImageWhiteContent.vue";
import OpenTextGallery from "@/components/Highlights/OpenTextGallery.vue";
import TallBackgroundImageOrangeContent from "@/components/Highlights/TallBackgroundImageOrangeContent.vue";

export default defineComponent({
  name: "FeatureSetsBlock",
  components: {
    OpenTextOrangeBackground,
    HeadingScrollingBackground,
    BasicHeadingDescription,
    TallBackgroundImageWhiteContent,
    OpenTextGallery,
    TallBackgroundImageOrangeContent,
  },
  props: {
    featureSetsData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    capitalize(text: string) {
      return capitalize(text);
    },
  },
});
