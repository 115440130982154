import helpers from "./helpers/helpers";
import seo from "@/api/components/common/seo";
import hero from "@/api/components/common/hero";
import featureSets from "@/api/components/common/featureSets";
import careerOpenings from "@/api/components/career/careerOpenings";

export default {
  loadOurStoryPage() {
    const requestData = {
      query: `{
        entry(slug: "our-story") {
          ... on ourStory_ourStory_Entry {
            ${seo}
            ${hero}
            ${featureSets}
            ${careerOpenings}
          }
        }
      }`,
    };

    return helpers.makeContentRequest(requestData, "ourStoryPage");
  },
};
