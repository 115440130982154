import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f43b3678"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "social-media-links-container" }
const _hoisted_2 = { class: "social-links" }
const _hoisted_3 = {
  key: 0,
  class: "social-link"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 1,
  class: "social-link"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 2,
  class: "social-link"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 3,
  class: "social-link"
}
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.socialMediaLinks.facebookLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("a", {
              href: _ctx.socialMediaLinks.facebookLink,
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "fa-brands fa-facebook"
              })
            ], 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      (_ctx.socialMediaLinks.instagramLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("a", {
              href: _ctx.socialMediaLinks.instagramLink,
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "fa-brands fa-instagram"
              })
            ], 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      (_ctx.socialMediaLinks.linkedinLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("a", {
              href: _ctx.socialMediaLinks.linkedinLink,
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "fa-brands fa-linkedin"
              })
            ], 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true),
      (_ctx.socialMediaLinks.twitterLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("a", {
              href: _ctx.socialMediaLinks.twitterLink,
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "fa-brands fa-twitter"
              })
            ], 8, _hoisted_10)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}