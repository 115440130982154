<template>
  <AnimationObserver @showing="initAnimation" :threshold="threshold">
    <div
      :style="[
        styles,
        !animate ? { transform: 'translateY(' + bottomPercentage + ')' } : {},
      ]"
      :class="[{ animate }, 'animated-component']"
    >
      <slot />
    </div>
  </AnimationObserver>
</template>

<script>
import AnimationMixin from "@/components/UI/Animations/AnimationMixin";
import AnimationObserver from "@/components/UI/Animations/AnimationObserver";

export default {
  name: "BottomToTopFade",
  components: { AnimationObserver },
  mixins: [AnimationMixin],
  props: {
    bottomPercentage: {
      type: String,
      default: () => "25%",
    },
  },
};
</script>

<style lang="scss" scoped>
.animated-component {
  @apply relative;

  &:not(.animate) {
    @apply opacity-0;
  }

  .animate {
    @apply opacity-100;
    transform: translateY(0);
  }
}
</style>
