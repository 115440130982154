import axios, { AxiosError, AxiosResponse } from "axios";
import { useToast } from "vue-toast-notification";
import { stringify } from "qs";
import { serialize } from "object-to-formdata";

const $toast = useToast();
const m = document.location.href.match(/\btoken=([^&]+)/);
const previewToken = m ? m[1] : "";
const requestPath = previewToken === "" ? "/api" : "/api?token=" + previewToken;
const Axios = axios.create({
  baseURL: /*process.env.VUE_APP_BASE_PATH +*/ requestPath,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`,
  },
});
const Axios2 = axios.create({
  baseURL: "/",
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`,
  },
});

export default {
  submitContactForm(payload: { attachment: FileList }) {
    if (payload.attachment !== null) {
      console.log("using serializer");
      return Axios2.post("/actions/contact-form/send", serialize(payload));
    }
    return Axios2.post("/actions/contact-form/send", stringify(payload));
  },
  getSessionInfo() {
    return Axios2.get("/actions/users/session-info");
  },
  makeContentRequest(requestData: any, storageKey: string): any {
    return Axios.post("", requestData)
      .then((response: AxiosResponse) => response.data)
      .then((data: any) => {
        if (this.dataHasErrors({ data: data })) return data;

        const response = data.data;
        this.setPageTitle(response);
        this.storeResponse(response, storageKey);

        return response;
      })
      .catch((error: AxiosError) => {
        return this.readStoredResponse(storageKey, error.response);
      });
  },

  dataHasErrors({ data }: { data: any }) {
    const errors = data.errors || data.error;

    if (errors !== undefined) {
      $toast.error("Error encountered: " + this.extractErrorMessage(errors));
      return true;
    }
    return false;
  },

  setPageTitle(response: { entry: { seo: { title: string } } }) {
    if (response.entry && response.entry.seo && response.entry.seo.title) {
      const element = document.createElement("div");
      element.innerHTML = response.entry.seo.title;

      if (element && element.textContent !== null) {
        document.title = element.textContent;
      }
    }
  },

  storeResponse(response: unknown, storageKey: string) {
    localStorage.setItem(storageKey, JSON.stringify(response));
  },

  readStoredResponse(
    storageKey: string,
    errorResponse: AxiosResponse<unknown, any> | undefined | null
  ) {
    const storedData: string | null = localStorage.getItem(storageKey);

    if (!storedData && !!errorResponse) {
      this.dataHasErrors({ data: errorResponse.data });
    } else if (!storedData) {
      return null;
    } else {
      return JSON.parse(storedData);
    }
  },

  extractErrorMessage(errors: string | [{ message: string }]) {
    // errors[0].message || errors[0] || errors
    if (typeof errors === "string") {
      return errors;
    } else if (typeof errors === "object") {
      return errors[0].message || errors[0];
    }
  },

  slugify(string: string) {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  },
};
