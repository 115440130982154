export default `documentsList {
    ...on documentsList_defaultDocumentList_BlockType {
        documents {
            ...on documents_BlockType {
                id,
                documentTitle,
                document {
                    id,
                    title,
                    url
                }
            }
        }
        partnerTitle,
        partners {
            ...on partners_BlockType {
                id,
                partnerUrl,
                partnerLogo {
                    id,
                    title,
                    url
                }
            }
        }
    }
}`;
