export default `...on featureSets_tallBackgroundImageOrangeContent_BlockType {
    id,
    typeHandle,
    backgroundImage {
        id,
        url,
        title,
    }
    titleText,
    description
}`;
