export default `...on featureSets_openTextOrangeBackground_BlockType {
    id,
    typeHandle,
    openText,
    button {
        ...on button_BlockType {
            id,
            linkTitle,
            hyperlink,
            newTab,
        }
    }
}`;
