import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b71e568"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-heading-description-container" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.featureSetData.titleText), 1),
        _createElementVNode("div", {
          class: "text",
          innerHTML: _ctx.$filters.nl2br(_ctx.featureSetData.descriptionText)
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}