
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "PageWrapper",
  data() {
    return {
      show: false as boolean,
    };
  },
  computed: {
    ...mapState("page", {
      showPage: "showPage",
    }),
  },
  watch: {
    $route() {
      if (this.showPage) {
        // Toggle to false on route change.
        this.toggleShowPage();
        this.toggleLoadingOverlay(false);
      }
    },
    showPage(newVal) {
      if (!newVal) {
        document.title = "Loading... - Sunmet Fruit";
      }

      // Wait for the css animation to complete and then hide the element.
      setTimeout(() => {
        this.toggleLoadingOverlay(newVal);
      }, 600);
    },
  },
  mounted() {
    this.toggleLoadingOverlay(this.showPage);
  },
  methods: {
    ...mapActions("page", {
      toggleShowPage: "toggleShowPage",
    }),
    toggleLoadingOverlay(hide = true) {
      const loadingOverlayEl = this.$refs.loadingOverlay as HTMLDivElement;
      if (!loadingOverlayEl) return;

      if (hide) {
        loadingOverlayEl.classList.add("hidden");
        return;
      }
      loadingOverlayEl.classList.remove("hidden");
    },
  },
});
